import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src750413537/src/src/layout/defaultLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Section = makeShortcode("Section");
const Box = makeShortcode("Box");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Section mdxType="Section">
      <h1 {...{
        "id": "pull-requests"
      }}>{`Pull Requests`}</h1>
      <p>{`Pull Requests are a way of fostering collaboration, improving code quality and reducing bugs. All repositories should have a similar branch rule set where individuals may not push directly to the `}<em parentName="p">{`main`}</em>{` branch.`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#template"
          }}>{`Template`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#actions"
          }}>{`Actions`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#testing"
          }}>{`Testing`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#dependabot"
          }}>{`Dependabot`}</a></li>
      </ul>
    </Section>
    <Section mdxType="Section">
      <h2 {...{
        "id": "template"
      }}>{`Template`}</h2>
      <p>{`The main template used for pull requests has the following format and should be included in each repo as the default.`}</p>
      <p>{`A default PR template can be added to the repo's `}<inlineCode parentName="p">{`.github/`}</inlineCode>{` folder as `}<inlineCode parentName="p">{`pull_request_template.md`}</inlineCode></p>
      <h4 {...{
        "id": "notes"
      }}>{`NOTES`}</h4>
      <ul>
        <li parentName="ul">{`PR Title should contain TP Card Id with a description`}<br parentName="li"></br>
          {`-U123: Update invalid contact message`}<br parentName="li"></br>
          {`-B321: 503262 - Fix domain search crashing`}</li>
        <li parentName="ul">{`Branch name should NOT have slashes AND should contain the TP Card Id`}<br parentName="li"></br>
          {`-U123-invalid_contact_message`}<br parentName="li"></br>
          {`-B231-503262_broken_domain_search`}</li>
      </ul>
      <h4 {...{
        "id": "description"
      }}>{`Description`}</h4>
      <ul>
        <li parentName="ul">{`TP ID (TP 12345)`}</li>
        <li parentName="ul">{`TP URL Link (`}<a parentName="li" {...{
            "href": "https://tpurl.example/12345"
          }}>{`https://tpurl.example/12345`}</a>{`)`}</li>
        <li parentName="ul">{`Short description of the change (Fix issue with login page.)`}</li>
      </ul>
      <h4 {...{
        "id": "related-prs"
      }}>{`Related PR's`}</h4>
      <p>{`List other open PR's that are part of this work, with links.`}</p>
      <ul>
        <li parentName="ul">{`rebelwebui/pr/link`}</li>
        <li parentName="ul">{`rebelsearch/pr/link`}</li>
      </ul>
      <h4 {...{
        "id": "affected-areas"
      }}>{`Affected Areas`}</h4>
      <p>{`This section should contain areas of the application this change touches.`}</p>
      <ul>
        <li parentName="ul">{`API`}</li>
        <li parentName="ul">{`Login UI`}</li>
      </ul>
      <h4 {...{
        "id": "reviewer-checklist"
      }}>{`Reviewer Checklist`}</h4>
      <p>{`[ ]`}{` Build and tests completed successfully`}<br parentName="p"></br>{`
`}{`[ ]`}{` Follows Rebel code guidelines`}</p>
    </Section>
    <Section mdxType="Section">
      <h2 {...{
        "id": "github-actions"
      }}>{`Github Actions`}</h2>
      <p><a parentName="p" {...{
          "href": "https://github.com/features/actions"
        }}>{`Github Actions`}</a>{`, defined in the repository's `}<inlineCode parentName="p">{`.github/workflows/`}</inlineCode>{` folder in a .yml file, describe any extra work to be done on a PR for each commit.`}</p>
      <p>{`For example, actions are where we define any PR-level testing jobs. All actions/checks must be passing in order for a Pull Requests to be deemed safe to merge.`}</p>
    </Section>
    <Section mdxType="Section">
      <h2 {...{
        "id": "testing"
      }}>{`Testing`}</h2>
      <p>{`Testing at the PR-level can have some philosophical elements. For example, which type tests do you run? Do we test for coverage and at which threshold? Do we really need 100% test coverage? etc...`}</p>
      <p>{`At a high level, we run unit tests on components and end-to-end tests on features. Ideally, utility functions should are pure and unit tested.`}</p>
      <Box bg="greyLightest" mb={3} p={3} borderRadius={1} mdxType="Box">
  <strong>
    <i>
      "A pure function is a function where the return value is only determined
      by its input values, without observable side effects."
    </i>
  </strong>
      </Box>
      <p>{`For this reason, certain repos will have GitHub Actions for `}<a parentName="p" {...{
          "href": "https://jestjs.io/"
        }}>{`Jest`}</a>{` test specs, and others for `}<a parentName="p" {...{
          "href": "https://www.cypress.io/"
        }}>{`Cypress`}</a>{` test specs`}</p>
      <p>{`At a high lever, use Cypress to test a feature's "happy path" to ensure it general works as expected. Edge cases and bug fixes can be tested using Jest integration tests. Jest should be used at the component and pure function level.`}</p>
    </Section>
    <Section mdxType="Section">
      <h2 {...{
        "id": "dependabot"
      }}>{`Dependabot`}</h2>
      <p><a parentName="p" {...{
          "href": "https://dependabot.com/"
        }}>{`Dependabot`}</a>{` is an extra tool used to keep npm packages up to date.`}</p>
      <p>{`With options defined in a repository's `}<inlineCode parentName="p">{`.dependabot/config.yml`}</inlineCode>{` file, Pull Requests should be automatically created at regular intervals for available package updates. Most are currently set at one week intervals.`}</p>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      